import React, { useEffect, useRef } from 'react'
import Talk from 'talkjs'
import { Pack } from '../types'
import { generateRandomString } from '../utils'

interface TalkJSChatboxProps {
  onOpenModal: ({ pack, packId }: { pack?: Pack; packId?: string }) => void
  partner: string
}

const PLACEHOLDER_MESSAGE = 'Theo is writing...'

const TalkJSChatbox: React.FC<TalkJSChatboxProps> = ({ onOpenModal, partner }) => {
  const chatboxContainer = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    let userId = generateRandomString(20)
    let conversationId = `${partner}_${generateRandomString(20)}`

    Talk.ready.then(() => {
      const me = new Talk.User({
        id: userId,
        name: 'User',
        email: null,
        role: 'chatbotUser',
      })

      const other = new Talk.User({
        id: process.env.REACT_APP_THEO_TALKJS_USER_ID!,
        name: 'THEO',
        email: null,
        role: 'chatbot',
        photoUrl: 'https://firebasestorage.googleapis.com/v0/b/neoswap-production.appspot.com/o/tulle_images%2FFrame%207.png?alt=media&token=8293bd4e-4a8e-4dbd-ac4f-fdedf4cb1235',
        welcomeMessage:
          'Hello! I can help you to find the right Genopets pack for you. Tell me, what are you looking for?',
      })

      const session = new Talk.Session({
        appId: process.env.REACT_APP_TALKJS_APP_ID!,
        me: me,
      })

      const conversation = session.getOrCreateConversation(conversationId)

      conversation.setParticipant(me)
      conversation.setParticipant(other)

      const chatbox = session.createChatbox({
        theme: 'neoswapChatbot',
      })
      chatbox.select(conversation)
      chatbox.mount(chatboxContainer.current!)

      chatbox.onCustomMessageAction('buy', (event) => {
        console.log('Action has been triggered!', event.message.custom)
        if (event.message.custom.pack) {
          const pack = JSON.parse(event.message.custom.pack) as Pack
          console.log('Pack', pack)
          onOpenModal({ pack })
        } else {
          const packId = event.message.custom.packId
          onOpenModal({ packId })
        }
      })

      // chatbox.onSendMessage((message) => {
      //   const updateParticipants = async () => {
      //     // await to make sure the message is sent before updating the participants
      //     await sleep(200)
      //     conversation.setParticipant(me, { access: 'Read' })
      //     chatbox.select(conversation)
      //   }

      //   updateParticipants()
      // })

      session.onMessage(async (message) => {
        console.log('Message received', message)
        if (message.custom.unlock === 'true') {
          conversation.setParticipant(me, { access: 'ReadWrite' })
          chatbox.select(conversation)
        }
      })

      return () => session.destroy()
    })
  }, [])

  return <div ref={chatboxContainer} className="chatbot-container" />
}

export default TalkJSChatbox
