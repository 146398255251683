import React from 'react';
import { Pack } from '../types/packs';

const PackCard: React.FC<{
  pack: Pack;
  onOpenModal: ({ pack, packId }: { pack?: Pack, packId?: string }) => void
}> = ({
  pack,
  onOpenModal,
}) => {
    return (
      <div className="pack-card">
        <div className="pack-card-content">
          <div className="pack-card-title">
            {pack.name}
          </div>

          <div className="pack-card-divider" />

          <div className="pack-card-items">
            {pack.summary?.map((item) => (
              <div key={item?.details?.id} className="pack-card-item">
                <div className="pack-card-item-content">
                  <div className="pack-card-item-image">
                    <img src={item?.details?.image} alt={item?.details?.name} className="pack-card-item-img" />
                  </div>
                  <span className="pack-card-item-name">{item?.details?.name}</span>
                  <span className={`pack-card-item-quantity`}>x{item.quantity}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
        <button className="pack-card-button" onClick={() => {
          onOpenModal({ pack })
        }}>
          BUY PACK
        </button>
      </div>
    );
  };

export default PackCard;
