import React from 'react'
import { Pack } from '../../../types/packs'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Divider,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react'
import { StateFrom } from 'xstate'
import { PackTransaction } from '../types'
import TransactionTable from './TransactionTable'

type RightPanelProps = {
  state: StateFrom<any>
  pack?: Pack
  uiPrice?: string
  transactions: PackTransaction[]
  previousTxs: PackTransaction[]
}

const RightPanel: React.FC<RightPanelProps> = ({ state, pack, uiPrice, transactions, previousTxs }) => {
  const isLoadingTransactions = state.matches('fetchTxs')

  return (
    <VStack
      spacing={0}
      ml={{ md: 6 }}
      width={"100%"}
      className='buying-modal-right'
      flexShrink={0}
      alignItems="center"
      justifyContent="space-between"
    >
      <VStack width={"100%"}>
        <HStack justifyContent="space-between" width={"100%"} marginTop={"10px"}>
          <Text className="buying-modal-price-title">Pack:</Text>
          <Text className="buying-modal-price-title" style={{ fontStyle: "italic" }}>{pack?.name}</Text>
        </HStack>
        <Divider />
        <HStack justifyContent="space-between" width={"100%"}>
          <Text className="buying-modal-price-title">Price:</Text>
          <Text className="buying-modal-price-value">{uiPrice}</Text>
        </HStack>
        <Accordion width={"100%"} allowToggle defaultIndex={1}>
          <AccordionItem>
            <h2>
              <AccordionButton style={{ paddingLeft: "0px" }}>
                <Box as="span" flex="1" textAlign="left">
                  Description
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>{pack?.description}</AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton style={{ paddingLeft: "0px" }}>
                <Box as="span" flex="1" textAlign="left">
                  Transactions
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <TransactionTable
                isLoadingTransactions={isLoadingTransactions}
                transactions={transactions}
                previousTxs={previousTxs}
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </VStack>
      <Alert status="info" className="buying-modal-alert" borderRadius={'4px'} width="full">
        <AlertIcon className="buying-modal-alert-icon" />
        <AlertDescription className="buying-modal-alert-description">
          Purchase price includes 5% NeoSwap convenience fee.
        </AlertDescription>
      </Alert>
    </VStack>
  )
}

export default RightPanel
