import { useEffect } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import Layout, { HomeLayout } from "./components/layout";
import { ProtectedRoute } from "./components/protectedRoute";
import Connect from "./pages/Connect";
import CreateRoom from "./pages/CreateRoom";
import Home from "./pages/Home";
import MyItems from "./pages/MyItems";
import PartyPreview from "./pages/PartyPreview";
import RoomDetail from "./pages/RoomDetails";
import Rooms from "./pages/Rooms";
import Waitlist from "./pages/Waitlist";
import Feedback from "./pages/Feedback";
import RoomProvider from "./contexts/roomContext";
import Updates from "./pages/Updates";
import Faq from "./pages/Faq";
import SmartAuctionPage from "./pages/SmartAuctionPage";
import Profile from "./pages/Profile";
import MySwaps from "./pages/MySwaps";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Terms from "./pages/Terms";
import Otc from "./pages/Otc";
import AiTradesV1 from "./pages/AiTradesV1";
import AiTrades from "./pages/AiTrades";
import PayWithNS from "./pages/PayWithNS";
import EmailVerification from "./pages/EmailVerification";
import ConnectExtension from "./pages/ConnectExtension";
import OffersPage from "./pages/AiOffers";
import BuyDownWaitlist from "./pages/BuyDownWaitlist";
import AiHistory from "./pages/AiHistory";
import Image from "./pages/Image";
import TicketDispenser from "./pages/TicketDispenser";
import Points from "./pages/Points";
// import TradeCalculator from "./pages/TradeCalculator";
import StarAtlasSkins from "./pages/StarAtlasSkins";
import StarAtlasPacks from "./pages/StarAtlasPacks";
// import CollectionSwap from "./pages/CollectionSwap";
import CollectionSwap from "./pages/CollectionSwapDisabled";
import Genopets from "./pages/Genopets";
import TwitterOAuthCallback from "./pages/TwitterCallbackRedirect";

export default function Router() {
  const location = useLocation();

  // This closes mobile menu on page change
  useEffect(() => {
    let toggle = document.getElementById("toggle");

    if (toggle) {
      (toggle as HTMLInputElement).checked = false;
    }
  }, [location]);

  return (
    <Routes>
      <Route index element={<Home />} />
      <Route path="/imageogtest/:imageUrl" element={<Image />} />
      <Route path="faq" element={<Faq />} />
      <Route path="privacy-policy" element={<PrivacyPolicy />} />
      <Route path="events" element={<SmartAuctionPage />} />
      <Route path="updates" element={<Updates />} />
      <Route path="feedback" element={<Feedback />} />
      <Route path="waitlist" element={<Waitlist />} />
      <Route path="/" element={<Layout />}>
        <Route path="terms-of-service" element={<Terms />} />
        {/* <Route path="calculator" element={<TradeCalculator />} /> */}
        <Route path="star-atlas" element={<StarAtlasPacks />} />
        <Route path="star-atlas-skins" element={<StarAtlasSkins />} />
        <Route path="collection-swap" element={<CollectionSwap />} />
        <Route path="genopets" element={<Genopets />} />
        <Route
          path="terms-and-conditions"
          element={<Navigate to="/terms-of-service" />}
        />
        <Route path="connect" element={<Connect />} />
        <Route path="connect/extension" element={<ConnectExtension />} />
        <Route
          path="otc-swap"
          element={
            <ProtectedRoute>
              <Otc />
            </ProtectedRoute>
          }
        />


        <Route
          path="ticket-dispenser"
          element={
            <TicketDispenser />
          }
        />
        <Route
          path="points"
          element={
            <ProtectedRoute>
              <Points />
            </ProtectedRoute>
          }
        />
        <Route
          path="ai-trades"
          element={
            <ProtectedRoute>
              <AiTrades />
            </ProtectedRoute>
          }
        />
        <Route
          path="pay-with-neoswap"
          element={
            <ProtectedRoute>
              <PayWithNS />
            </ProtectedRoute>
          }
        />
        <Route
          path="pay-with-neoswap/:roomId"
          element={
            <ProtectedRoute>
              <OffersPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="ai-history"
          element={
            <ProtectedRoute>
              <AiHistory />
            </ProtectedRoute>
          }
        />
        <Route path="bdn-waitlist" element={<BuyDownWaitlist />} />
        <Route
          path="ai-trades/:roomId"
          element={
            <ProtectedRoute>
              <OffersPage />
            </ProtectedRoute>
          }
        />

        <Route
          path="preview/:roomId"
          element={
            <RoomProvider isPreview>
              <PartyPreview />
            </RoomProvider>
          }
        />
        <Route
          path="profile"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />
        {/* <Route
          path="verify-email"
          element={
            <ProtectedRoute>
              <EmailVerification />
            </ProtectedRoute>
          }
        /> */}
        <Route
          path="twitter-callback"
          element={
            <TwitterOAuthCallback />
          }
        />
        <Route
          path="my-items"
          element={
            <ProtectedRoute>
              <MyItems />
            </ProtectedRoute>
          }
        />
        <Route
          path="my-swaps"
          element={
            <ProtectedRoute>
              <MySwaps />
            </ProtectedRoute>
          }
        />
        <Route path="rooms">
          <Route
            path=":roomId"
            element={
              <ProtectedRoute>
                <RoomProvider>
                  <RoomDetail />
                </RoomProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path="create"
            element={
              <ProtectedRoute>
                <CreateRoom />
              </ProtectedRoute>
            }
          />
          <Route index element={<Rooms />} />
        </Route>
        <Route
          path="/:slug"
          element={
            <ProtectedRoute>
              <AiTrades />
            </ProtectedRoute>
          }
        />
      </Route>
    </Routes>
  );
}
