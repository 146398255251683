import { useState, useEffect } from 'react';
import {
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  ModalHeader,
  HStack,
  VStack,
  Box,
  Text,
  Flex,
  useToast,
  Alert,
  AlertDescription,
  AlertIcon,
  Link,
  Grid,
  Image,
  GridItem,
  Skeleton,
  Radio,
  RadioGroup
} from "@chakra-ui/react";
import useNeoChain from "../../hooks/useNeoChain";
import { BundleSpec, BundleTransaction, ItemSpec, BundleDetails, Payment, BuddyDetails, JupSwap } from "../Bundles/types";
import { useAppContext } from '../../contexts/appContext';
import { confetti } from "../../services/confetti";
import { tokens } from "../Bundles/consts";
import { useUA } from "../../contexts/userTracking";


export default function BuyingModal({
  offer,
  paymentOptions,
  isOpen,
  onClose,
}: {
  offer: BundleDetails;
  paymentOptions: Payment[];
  isOpen: boolean;
  onClose: () => void;
}) {
  const {
    gaPackSelectPayment,
    gaPackSignTransaction,
    gaPackUpdateTransaction,
    gaPackSuccess,
  } = useUA();
  const { isOpen: isOpenFailedMain, onOpen: onOpenFailedMain, onClose: onCloseFailedMain } = useDisclosure();
  const { isOpen: isOpenFailedPrice, onOpen: onOpenFailedPrice, onClose: onCloseFailedPrice } = useDisclosure();
  const [step, setStep] = useState(0);
  const [transactions, setTransactions] = useState<BundleTransaction[] | null>(null);
  const [bundleSpecValue, setBundleSpecValue] = useState<BundleSpec | null>(null);
  const [currentPriority, setCurrentPriority] = useState<number>(0);
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState<string>("usdc");
  const [isLoadingTransactions, setIsLoadingTransactions] = useState(false);
  const [isBuying, setIsBuying] = useState(false);
  const [mainBtnLabel, setMainBtnLabel] = useState("Buy");
  const [currentWarning, setCurrentWarning] = useState<any>({});
  const [shouldSignTransactions, setShouldSignTransactions] = useState(false);
  const [onOpenSign, setOnOpenSign] = useState(true);

  const chainFns = useNeoChain() as any;
  const toast = useToast();
  const {
    connected,
    connect,
    getTransactions,
    signTransactions,
    checkTransactions,
    getRetryTransactions,
    currencyToName,
    signIn,
  } = chainFns["solana" || "not_found"];

  const {
    uid,
  } = useAppContext();

  const paymentMethodLabels = {
    listingCurrency: "LISTING TOKENS",
    atlas: "ATLAS",
    usdc: "USDC",
    native: "SOL",
  };

  const paymentOption = paymentOptions.find(p => p.id === currentPaymentMethod);
  let currentCurrencyPrice = "0.00";
  let additionalCurrencyPrice = "0.00";

  if (paymentOption) {
    let currentCurrencyKey = currencyToName(paymentOption.details[0].mint) as keyof typeof tokens;
    const decimals = tokens[currentCurrencyKey].decimals;
    currentCurrencyPrice = (paymentOption.details[0].price / (10 ** decimals)).toFixed(2);

    let additionalKey = "" as keyof typeof tokens;
    if (paymentOption.method === "listingCurrency" && paymentOption.details[1]) {
      additionalKey = currencyToName(paymentOption.details[1].mint) as keyof typeof tokens;
      additionalCurrencyPrice = (paymentOption.details[1].price / (10 ** tokens[additionalKey].decimals)).toFixed(2);
    }
  }

  const simulate = false;

  useEffect(() => {
    if (uid) {
      console.log("uid", uid);
      const userPbKey = uid.replace("solana-", "");

      const newBundleSpecValue: BundleSpec = {
        ...offer,
        user: userPbKey,
        payment: paymentOption || paymentOptions[0],
      };

      setBundleSpecValue(newBundleSpecValue);
    }
  }, [uid, offer, currentPaymentMethod]);

  useEffect(() => {
    if (uid && !connected) {
      connect();
    }
  }, [uid, connect]);

  useEffect(() => {
    if (transactions) {
      if (transactions.every(tx => tx.status === 'success') && transactions.length > 0) {
        setMainBtnLabel("Share");
        setCurrentPriority(0);
      } else if (transactions.some(tx => tx.status === 'broadcast' || isBuying)) {
        setMainBtnLabel("Buying");
      } else {
        setMainBtnLabel("Buy");
      }
    }
  }, [transactions, isBuying]);

  useEffect(() => {
    if (transactions && onOpenSign) {
      setShouldSignTransactions(true);
      setOnOpenSign(false);
    }
  }, [transactions]);

  const onCloseMainWithReset = () => {
    onClose();
    setStep(0);
    setTransactions(null);
    setMainBtnLabel("Buy");
    setIsBuying(false);
    setCurrentPriority(0);
    setOnOpenSign(true);
  };

  const fetchTransactions = async () => {
    if (bundleSpecValue) {
      setIsLoadingTransactions(true);
      gaPackSelectPayment(
        bundleSpecValue.uniqueId,
        bundleSpecValue.id,
        bundleSpecValue.payment.id,
        bundleSpecValue.payment.details[0].mint,
        bundleSpecValue.payment.details[0].price
      );
      const txs = await getTransactions(bundleSpecValue, true);
      console.log("txs", txs);
      setTransactions(txs);
      setIsLoadingTransactions(false);
    }
  };

  const getTxGaData = (tx: BundleTransaction, isRetry = false): Object => {
    let txGaData: Object = {
      description: tx.description,
      status: tx.status,
      is_retry: isRetry,
    }

    if (tx.details && 'mint' in tx.details) {
      // Add type check for ItemSpec
      const itemSpec = tx.details as ItemSpec;
      txGaData = {
        ...txGaData,
        type: "item",
        item_mint: itemSpec.mint,
        item_type: itemSpec.type,
        item_quantity: itemSpec.quantity,
        item_payment_mint: itemSpec.currency,
      }
    } else if (tx.details && 'inToken' in tx.details) {
      // Add type check for JupSwap
      const jupSwap = tx.details as JupSwap;
      txGaData = {
        ...txGaData,
        type: "jupSwap",
        in_token: jupSwap.inToken,
        in_amount_limit: jupSwap.inAmountLimit,
        out_token: jupSwap.outToken,
        out_amount: jupSwap.outAmount,
      }
    } else if (tx.details && 'address' in tx.details) {
      // Add type check for BuddyDetails
      const buddyDetails = tx.details as BuddyDetails;
      txGaData = {
        ...txGaData,
        type: "buddy",
      }
    }

    return txGaData;
  };

  const handleSignTransactions = async () => {
    if (transactions) {
      setIsBuying(true);
      let priority = currentPriority;
      try {
        let minPriority = Math.min(...transactions.filter(tx => tx.status === "pending").map(tx => tx.priority));
        priority = minPriority;

        const txsToSign = transactions.filter(tx => tx.priority === priority && tx.status === "pending");
        if (txsToSign.length === 0) {
          if (transactions.some(tx => tx.priority === priority + 1)) {
            setCurrentPriority(priority + 1);
          }
          setIsBuying(false);
          return;
        }
        const result = await signTransactions(txsToSign, null, simulate);
        const updatedTransactions = transactions.map(tx => {
          const resultTx = result.find((rTx: BundleTransaction) => rTx.hash === tx.hash);
          if (resultTx) {
            let txGaData = getTxGaData(resultTx);
            gaPackSignTransaction(
              bundleSpecValue?.uniqueId,
              bundleSpecValue?.id || "",
              resultTx.hash,
              txGaData,
            );
          }
          return resultTx || tx;
        });
        setTransactions(updatedTransactions);
      } catch (error) {
        setIsBuying(false);
        console.log("error", error);
        toast({
          title: "Error!",
          description: "Please report a bug through the feedback button.",
          status: "error",
          duration: 9000,
          isClosable: true,
          styleConfig: {
            zIndex: 999999,
          },
        });
      }
      setCurrentPriority(priority + 1);
    }
  }

  const handleShare = () => {
    var shareURL = "https://twitter.com/intent/tweet?"; //url base

    // gaSharedStarAtlasSkin(skinType);
    //params
    var params: { [key: string]: string } = {
      url: "https://neoswap.cc/star-atlas",
      text: `I got my @StarAtlas pack on @neoswap_ai! \n\nGet yours at: `,
    };

    for (var prop in params)
      shareURL += "&" + prop + "=" + encodeURIComponent(params[prop]);
    window.open(
      shareURL,
      "",
      "left=0,top=50,width=550,height=450,personalbar=0,toolbar=0,scrollbars=0,resizable=0"
    );
  };

  const handleRetry = async () => {
    if (transactions && uid) {
      setIsLoadingTransactions(true);
      setIsBuying(true);
      const userPbKey = uid.replace("solana-", "");
      const { bundleTx: retryTxs, warning } = await getRetryTransactions(transactions, userPbKey);
      setIsLoadingTransactions(false);
      setOnOpenSign(true);
      setTransactions(retryTxs);
      if (warning) {
        setCurrentWarning(warning);
        onOpenFailedPrice();
      }
    }
  };

  useEffect(() => {
    let intervalId: NodeJS.Timer;

    const updateTransactionsStatus = async () => {
      let updatedTransactions: BundleTransaction[];
      if (!simulate) {
        updatedTransactions = await checkTransactions(transactions);
      } else {
        if (transactions) {
          updatedTransactions = transactions.map((tx, index) => (
            tx.status !== "broadcast" ? tx :
              index === 41 ? {
                ...tx,
                status: "failed",
              } : {
                ...tx,
                status: "success",
              }
          ));
        } else {
          updatedTransactions = [];
        }
      }
      setTransactions(currentTransactions => {
        return currentTransactions && currentTransactions.map(tx => {
          const update = updatedTransactions.find(u => u.hash === tx.hash);
          if (update?.status && update.status !== tx.status) {
            gaPackUpdateTransaction(
              bundleSpecValue?.uniqueId,
              bundleSpecValue?.id || "",
              update.hash || "",
              update.status,
            )
          }
          return update ? { ...tx, status: update.status } : tx;
        });
      });
      if (updatedTransactions.some(tx => tx.status === 'failed')) {
        setIsBuying(false);
        onOpenFailedMain();
      } else if (updatedTransactions.some(tx => tx.status === 'pending') &&
        updatedTransactions.filter(tx => tx.priority < currentPriority).every(tx => tx.status === 'success') &&
        !updatedTransactions.some(tx => tx.status === 'failed')) {
        setShouldSignTransactions(true);
      } else if (updatedTransactions.every(tx => tx.status === 'success')) {
        gaPackSuccess(
          bundleSpecValue?.uniqueId,
          bundleSpecValue?.id || "",
        )
        setIsBuying(false);
        confetti.fire();
      }

      if (shouldStopInterval(updatedTransactions)) {
        clearInterval(intervalId);
      }
    };

    if (transactions && transactions.some(tx => tx.status === 'broadcast')) {
      intervalId = setInterval(updateTransactionsStatus, 2000);
    }

    // Define a condition on when to stop the interval
    const shouldStopInterval = (updatedTransactions: BundleTransaction[]) => {
      return (
        updatedTransactions.every(tx => tx.status !== 'broadcast') ||
        !transactions ||
        transactions.length === 0
      );
    };

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [transactions, simulate, checkTransactions]);

  useEffect(() => {
    async function signRemainingTransactions() {
      if (shouldSignTransactions) {
        await handleSignTransactions();
        setShouldSignTransactions(false);
      }
    }

    signRemainingTransactions();
  }, [shouldSignTransactions]);

  const getIcon = (icon: string) => {
    switch (icon) {
      case "atlas":
        return (
          <div className='-mt-[2px]'>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="7.00004" cy="7.00004" r="6.66667" fill="white" />
              <path d="M7.19281 11.8693L5.8889 9.77776H8.453L7.19281 11.8693Z" fill="#32FEFF" />
              <path d="M7.00003 5.6424L5.24678 9.5429C5.18796 9.66804 5.15875 9.76955 5.03111 9.76955H3.87312C3.70705 9.76955 3.61548 9.72491 3.69666 9.54311L6.5281 3.15645C6.58692 3.05019 6.62181 2.97217 6.74945 2.97217H7.2508C7.37825 2.97217 7.41412 3.05019 7.47196 3.15645L10.3036 9.54311C10.3842 9.72491 10.2926 9.76955 10.1271 9.76955H8.96914C8.8415 9.76955 8.81229 9.66804 8.75347 9.5429L7.00003 5.6424Z" fill="#1A191C" />
            </svg>
          </div>
        );
      case "usdc":
        return (
          <div className='-mt-[2px]'>
            <svg width="18" height="18" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7 12.875C10.7406 12.875 13.75 10.0328 13.75 6.5C13.75 2.96716 10.7406 0.124998 7 0.124999C3.25935 0.124999 0.25 2.96717 0.250001 6.5C0.250002 10.0328 3.25935 12.875 7 12.875Z" fill="#2775CA" />
              <path d="M8.6875 7.43904C8.6875 6.51324 8.15858 6.19581 7.10074 6.06357C6.34514 5.95774 6.19402 5.74614 6.19402 5.37577C6.19402 5.0054 6.4459 4.76739 6.94962 4.76739C7.40298 4.76739 7.65486 4.92611 7.78078 5.32289C7.80598 5.40224 7.88154 5.45513 7.95711 5.45513L8.36005 5.45513C8.46082 5.45513 8.53638 5.37577 8.53638 5.27L8.53638 5.24353C8.43561 4.66156 7.98225 4.2119 7.40298 4.15901L7.40298 3.52416C7.40298 3.41833 7.32742 3.33897 7.2015 3.3125L6.8237 3.3125C6.72294 3.3125 6.64738 3.39186 6.62217 3.52416L6.62217 4.13254C5.86657 4.23837 5.38806 4.76739 5.38806 5.42872C5.38806 6.30164 5.89177 6.64548 6.94962 6.77778C7.65486 6.91002 7.88155 7.06873 7.88155 7.49199C7.88155 7.91525 7.52889 8.2062 7.05038 8.2062C6.39549 8.2062 6.16881 7.91518 6.09325 7.5184C6.0681 7.41263 5.99254 7.35969 5.91698 7.35969L5.48877 7.35969C5.38806 7.35969 5.3125 7.43904 5.3125 7.54487L5.3125 7.57135C5.41321 8.23261 5.81622 8.70875 6.64738 8.84105L6.64738 9.47591C6.64738 9.58167 6.72294 9.66103 6.84885 9.6875L7.22665 9.6875C7.32742 9.6875 7.40298 9.60815 7.42818 9.47591L7.42818 8.84105C8.18379 8.70875 8.6875 8.15325 8.6875 7.43904V7.43904Z" fill="white" />
              <path d="M5.56951 10.4199C3.38169 9.68907 2.25971 7.4182 3.07317 5.4083C3.49391 4.31202 4.41952 3.47677 5.5695 3.08524C5.68172 3.03306 5.7378 2.95475 5.7378 2.8242L5.7378 2.45879C5.7378 2.35437 5.68172 2.27606 5.5695 2.25C5.54143 2.25 5.48536 2.25 5.45729 2.27606C2.79266 3.05912 1.3341 5.69547 2.17556 8.17513C2.68045 9.63683 3.88657 10.7592 5.45729 11.2291C5.56951 11.2813 5.68172 11.2291 5.70973 11.1246C5.7378 11.0986 5.7378 11.0725 5.7378 11.0203L5.7378 10.6548C5.7378 10.5765 5.65365 10.4721 5.56951 10.4199ZM8.54271 2.27606C8.43049 2.22388 8.31828 2.27606 8.29027 2.38049C8.2622 2.40661 8.2622 2.43267 8.2622 2.48492L8.2622 2.85032C8.2622 2.95475 8.34635 3.05912 8.43049 3.11137C10.6183 3.84218 11.7403 6.11305 10.9268 8.12295C10.5061 9.21923 9.58048 10.0545 8.43049 10.446C8.31828 10.4982 8.2622 10.5765 8.2622 10.707L8.2622 11.0725C8.2622 11.1769 8.31828 11.2552 8.4305 11.2813C8.45857 11.2813 8.51464 11.2813 8.54271 11.2552C11.2073 10.4721 12.6659 7.83579 11.8244 5.35612C11.3196 3.8683 10.0854 2.74589 8.54271 2.27606V2.27606Z" fill="white" />
            </svg>
          </div>
        );
      case "native":
        return (
          <div className='-mt-0.5'>
            <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.27405 8.39558C2.35854 8.31088 2.47471 8.26147 2.59792 8.26147H13.7712C13.9753 8.26147 14.0774 8.50851 13.9331 8.6532L11.7259 10.8659C11.6414 10.9506 11.5252 11 11.402 11H0.228793C0.0246189 11 -0.0774681 10.753 0.0668618 10.6083L2.27405 8.39558Z" fill="url(#paint0_linear_12_3570)" />
              <path d="M2.27405 0.134103C2.36206 0.0494065 2.47823 0 2.59792 0H13.7712C13.9753 0 14.0774 0.247032 13.9331 0.391723L11.7259 2.60443C11.6414 2.68912 11.5252 2.73853 11.402 2.73853H0.228793C0.0246189 2.73853 -0.0774681 2.4915 0.0668618 2.34681L2.27405 0.134103Z" fill="url(#paint1_linear_12_3570)" />
              <path d="M11.7259 4.23835C11.6414 4.15365 11.5252 4.10425 11.402 4.10425H0.228793C0.0246189 4.10425 -0.0774681 4.35128 0.0668618 4.49597L2.27405 6.70868C2.35854 6.79337 2.47471 6.84278 2.59792 6.84278H13.7712C13.9753 6.84278 14.0774 6.59575 13.9331 6.45106L11.7259 4.23835Z" fill="url(#paint2_linear_12_3570)" />
              <defs>
                <linearGradient id="paint0_linear_12_3570" x1="12.7038" y1="-1.32181" x2="4.94065" y2="13.5107" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#00FFA3" />
                  <stop offset="1" stop-color="#DC1FFF" />
                </linearGradient>
                <linearGradient id="paint1_linear_12_3570" x1="9.3226" y1="-3.09148" x2="1.55946" y2="11.741" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#00FFA3" />
                  <stop offset="1" stop-color="#DC1FFF" />
                </linearGradient>
                <linearGradient id="paint2_linear_12_3570" x1="11.0024" y1="-2.2123" x2="3.23929" y2="12.6202" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#00FFA3" />
                  <stop offset="1" stop-color="#DC1FFF" />
                </linearGradient>
              </defs>
            </svg>
          </div>
        );
    }
  }

  const formatStatus = (string: string) => {
    let color = "";
    switch (string) {
      case "pending":
        color = "text-starAtlas-brightYellow";
        break;
      case "broadcast":
        color = "text-starAtlas-brightYellow";
        break;
      case "success":
        color = "text-green-500";
        break;
      case "failed":
        color = "text-red-500";
        break;
      default:
        color = "text-white";
        break;
    }

    return (
      <span className={`${color} capitalize`}>{string}</span>
    )
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onCloseMainWithReset}
        size="4xl"
        id="swap-modal"
        isCentered={true}
        scrollBehavior="inside"
        closeOnOverlayClick={false}
        trapFocus={false}
      >
        <ModalOverlay bg="#091824" />
        <ModalContent bg="#1D1D24" className='font-roganRegular'>
          <ModalHeader bg="#19191C" className='!pt-5'>
            <Flex justifyContent="space-between" alignItems="center">
              <Text className="text-starAtlas-brightYellow text-lg font-bold">{"//"}{offer?.description}</Text>
              <HStack spacing={4}>
                <Text className="text-white text-lg uppercase">Includes // <span className='text-starAtlas-brightYellow'>{offer.items.length} item{offer.items.length > 1 ? "s" : ""}</span></Text>
                <Text className="text-white text-lg uppercase flex gap-1">
                  Estimated Price // <span className='flex items-center gap-1'>{getIcon(currentPaymentMethod)}<span className='text-starAtlas-brightYellow'>{currentCurrencyPrice}</span></span>
                </Text>
              </HStack>
            </Flex>
          </ModalHeader>
          <ModalBody>
            <Flex direction={{ base: "column", md: "row" }} pt={5}>
              {/* Left Side: Items grid */}
              <div className='h-[340px] overflow-y-auto pr-2'>
                <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }} gap={6} flex="1">
                  {offer?.items?.map(({ name, quantity, image }, index: number) => (
                    <GridItem
                      key={`get_${name}_panel`}
                      colSpan={index === 0 ? { base: 1, md: 2 } : 1}
                      overflow="hidden"
                    >
                      <Box pb="2" bg="transparent" textAlign={index > 0 ? "center" : "left"}>
                        <Text fontWeight="bold" color="white">{name}</Text>
                      </Box>
                      <Box position="relative" h="125px" bg="#222228">
                        <Image src={image} alt={name} objectFit="cover" w="full" h="full" />
                        {quantity && (
                          <Box position="absolute" color="white" p={2} bottom="0" right="0" className='bg-neutral-900 bg-opacity-75'>
                            <Text fontSize="sm" fontWeight="bold" className='text-starAtlas-brightYellow'>
                              x {quantity}
                            </Text>
                          </Box>
                        )}
                      </Box>
                    </GridItem>
                  ))}
                </Grid>
              </div>

              {/* Right Side: Buying Status */}
              <VStack spacing={0} ml={{ md: 6 }} width={{ md: "full" }} maxWidth={{ md: "400px" }} flexShrink={0} alignItems="center" justifyContent="center">
                {step === 0 && (
                  <>
                    <div>
                      <Text className="text-white text-lg font-bold py-4 w-full uppercase">Choose payment method</Text>
                      <RadioGroup onChange={setCurrentPaymentMethod} value={currentPaymentMethod}>
                        <VStack spacing={4} justifyContent="center" alignItems="start">
                          {paymentOptions.map((method) => {
                            if (method.id === "listingCurrency") {
                              return null;
                            }
                            let currencyKey = currencyToName(method.details[0].mint) as keyof typeof tokens;
                            const decimals = tokens[currencyKey].decimals;
                            const price = (method.details[0].price / (10 ** decimals)).toFixed(2);

                            let additionalPrice = "";
                            let additionalKey = "" as keyof typeof tokens;
                            if (method.method === "listingCurrency" && method.details[1]) {
                              additionalKey = currencyToName(method.details[1].mint) as keyof typeof tokens;
                              additionalPrice = (method.details[1].price / (10 ** tokens[additionalKey].decimals)).toFixed(2);
                            }

                            return (
                              <Radio
                                key={`payment_method_${method.id}`}
                                value={method.id}
                                colorScheme="yellow"
                                size="lg"
                                disabled={isBuying}
                                className='radio-custom'
                              >
                                <div className='flex items-center gap-1'>
                                  {`${paymentMethodLabels[method.id]} `}
                                  {getIcon(currencyKey)}
                                  {` ${price}`}
                                  {method.method === "listingCurrency" ? (
                                    <>
                                      {getIcon(additionalKey)}
                                      {` ${additionalPrice}`}
                                    </>
                                  ) : ""}
                                </div>
                              </Radio>
                            );
                          })}
                        </VStack>
                      </RadioGroup>
                    </div>

                    {/* <Alert
                      status="info"
                      background={"#121117"}
                      borderRadius={"4px"}
                      width="full"
                      className='mt-10'
                    >
                      <AlertIcon color={"#FFBE4D"} />
                      <AlertDescription className='text-gray-100 text-xs tracking-tight'>
                        Purchase price includes 5% NeoSwap convenience fee.
                      </AlertDescription>
                    </Alert> */}
                  </>
                )}
                {step === 1 && (
                  <>
                    <Text className="text-white text-lg font-bold py-4 text-left w-full uppercase">Buying Status:</Text>
                    <Box overflowX="auto" width="full" overflowY="auto" maxHeight="230px">
                      <table className="w-full text-white border-separate border-spacing-y-2">
                        <thead>
                          <tr>
                            <th style={{ textAlign: 'left' }}>Action</th>
                            <th style={{ textAlign: 'left' }}>Tx hash</th>
                            <th style={{ textAlign: 'left' }}>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoadingTransactions ? (
                            Array.from({ length: 5 }).map((_, index) => (
                              <tr key={`skeleton_row_${index}`} className='bg-[#19191C] text-gray-100 text-xs p-2 h-10'>
                                <td className='pl-4'><Skeleton height="20px" /></td>
                                <td><Skeleton height="20px" /></td>
                                <td><Skeleton height="20px" /></td>
                              </tr>
                            ))
                          ) : transactions && transactions.sort(
                            (a, b) => a.priority - b.priority
                          ).map((tx, index) => (
                            <tr key={tx.description + '-' + index} className='bg-[#19191C] text-gray-100 text-xs p-2 h-10'>
                              <td className='pl-4'>{tx.description ? tx.description.slice(0, 30) : ""}</td>
                              <td>
                                {tx.hash ? (
                                  <Link
                                    href={`https://solscan.io/tx/${tx.hash}`}
                                    isExternal
                                    textDecoration="underline"
                                    _hover={{ textDecoration: "underline" }}
                                    color="#FFBE4D"
                                    _focus={{ boxShadow: "none" }}
                                  >
                                    {tx.hash.slice(0, 3) + "..." + tx.hash.slice(-3)}
                                  </Link>
                                ) : '...'}
                              </td>
                              <td>
                                {formatStatus(tx.status)} {
                                  tx.status === "failed" && (
                                    <>
                                      {" - "}
                                      <Button
                                        variant="link"
                                        colorScheme="blue"
                                        onClick={() => {
                                          if (!isBuying) {
                                            onOpenFailedMain();
                                          }
                                        }}
                                        _focus={{ boxShadow: "none" }}
                                      >
                                        Retry
                                      </Button>
                                    </>
                                  )
                                }
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Box>
                    {/* <Alert
                      status="info"
                      background={"#121117"}
                      borderRadius={"4px"}
                      width="full"
                    >
                      <AlertIcon color={"#FFBE4D"} />
                      <AlertDescription className='text-gray-100 text-xs tracking-tight'>
                        Purchase price includes 5% NeoSwap convenience fee.
                      </AlertDescription>
                    </Alert> */}
                  </>
                )}
              </VStack>
            </Flex>
          </ModalBody>
          <ModalFooter p={0} className="mt-6">
            <Button onClick={onCloseMainWithReset} flex="1" mr={3} py="4" rounded={0}>Close</Button>
            <Button className='!text-starAtlas-brightYellow' onClick={() => {
              if (step === 0) {
                if (!uid) {
                  onCloseMainWithReset();
                  signIn();
                } else {
                  fetchTransactions();
                  setStep(1);
                }
              } else if (step === 1) {
                if (transactions) {
                  if (transactions.length > 0 && isBuying === false && transactions.some(tx => tx.status === 'pending')) {
                    handleSignTransactions();
                  } else if (transactions.some(tx => tx.status === 'failed')) {
                    onOpenFailedMain();
                  } else if (transactions.every(tx => tx.status === 'success')) {
                    handleShare();
                  }
                }
              }
            }} flex="1" rounded={0} isLoading={((transactions && transactions.some(tx => tx.status === 'broadcast')) || (isLoadingTransactions && step == 1)) ?? false} disabled={isLoadingTransactions}>
              {mainBtnLabel}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal >


      <Modal
        isOpen={isOpenFailedMain}
        onClose={onCloseFailedMain}
        size="4xl"
        id="swap-modal"
        isCentered={true}
        scrollBehavior="inside"
        trapFocus={false}
      >
        <ModalOverlay bg="#091824" />
        <ModalContent bg="#1D1D24" className='font-roganRegular'>
          <ModalHeader bg="#19191C" pt={"1rem"}>
            PURSCHASE FAILED
          </ModalHeader>
          <ModalBody>
            <Alert
              status="info"
              background={"#121117"}
              borderRadius={"4px"}
              width="full"
            >
              <AlertIcon color={"#FFBE4D"} />
              <AlertDescription className='text-gray-100 text-xs tracking-tight'>
                Unfortunately, your purchase failed. Please try again.

                If you keep having issues, please use the 'Feedback' button to report it.
              </AlertDescription>
            </Alert>
          </ModalBody>
          <ModalFooter p={0}>
            <Button onClick={onCloseFailedMain} flex="1" mr={3} py="4" rounded={0}>Close</Button>
            <Button className='!text-starAtlas-brightYellow' onClick={() => {
              if (transactions) {
                if (transactions.length > 0 && isBuying === false && transactions.some(tx => tx.status === 'failed')) {
                  handleRetry();
                  onCloseFailedMain();
                }
              }
            }} flex="1" rounded={0} disabled={isLoadingTransactions}>
              TRY AGAIN
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal >

      <Modal
        isOpen={isOpenFailedPrice}
        onClose={onCloseFailedPrice}
        size="4xl"
        id="swap-modal"
        isCentered={true}
        scrollBehavior="inside"
        trapFocus={false}
      >
        <ModalOverlay bg="#091824" />
        <ModalContent bg="#1D1D24" className='font-roganRegular'>
          <ModalHeader bg="#19191C" pt={"1rem"}>
            PURSCHASE FAILED
          </ModalHeader>
          <ModalBody>
            <Alert
              status="info"
              background={"#121117"}
              borderRadius={"4px"}
              width="full"
            >
              <AlertIcon color={"#FFBE4D"} />
              <AlertDescription className='text-gray-100 text-xs tracking-tight'>
                The price of the item you're attempting to buy has been updated. To proceed, please sign the new transaction to swap the required amount.

                Once this is done, you can try buying the item again.

                If you keep having issues, please use the 'Feedback' button to report it.
              </AlertDescription>
            </Alert>
            <table className="w-full text-white border-separate border-spacing-y-2">
              <thead>
                <tr>
                  <th style={{ textAlign: 'left' }}>Action</th>
                  <th style={{ textAlign: 'left' }}>Tx hash</th>
                  <th style={{ textAlign: 'left' }}>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr key={"test"} className='bg-[#19191C] text-gray-100 text-xs p-2 h-10'>
                  <td className='pl-4'>{"test"}</td>
                  <td>
                    ...
                  </td>
                  <td>
                    {"pending"}
                  </td>
                </tr>
              </tbody>
            </table>
          </ModalBody>
          <ModalFooter p={0}>
            <Button onClick={onCloseFailedPrice} flex="1" mr={3} py="4" rounded={0}>Close</Button>
            <Button className='!text-starAtlas-brightYellow' onClick={() => {
              if (transactions) {
                if (transactions.length > 0 && isBuying === false && transactions.some(tx => tx.status === 'failed')) {
                  handleRetry();
                  onCloseFailedPrice();
                }
              }
            }} flex="1" rounded={0} disabled={isLoadingTransactions}>
              SIGN NOW
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal >
    </>
  );
};
