import React, { useEffect, useRef } from 'react'
import Talk from 'talkjs'
import { generateRandomString } from '../utils'

interface TalkJSButtonProps {
  onOpenModal: () => void
  partner: string
}

const TalkJSButton: React.FC<TalkJSButtonProps> = ({ onOpenModal, partner }) => {
  useEffect(() => {
    let userId = generateRandomString(20)
    let conversationId = `${partner}_${generateRandomString(20)}`

    Talk.ready.then(() => {
      const me = new Talk.User({
        id: userId,
        name: 'User',
        email: null,
        role: 'chatbotUser',
      })

      const other = new Talk.User({
        id: process.env.REACT_APP_THEO_TALKJS_USER_ID!,
        name: 'THEO',
        email: null,
        role: 'chatbot',
        photoUrl: 'https://firebasestorage.googleapis.com/v0/b/neoswap-production.appspot.com/o/tulle_images%2FFrame%207.png?alt=media&token=8293bd4e-4a8e-4dbd-ac4f-fdedf4cb1235',
        welcomeMessage:
          `Hello! I can help you to find the right Star Atlas pack for you. Tell me, what are you looking for?`,
      })

      const session = new Talk.Session({
        appId: process.env.REACT_APP_TALKJS_APP_ID!,
        me: me,
      })

      const conversation = session.getOrCreateConversation(conversationId)

      conversation.setParticipant(me)
      conversation.setParticipant(other)


      const popup = session.createPopup({
        theme: 'neoswapChatbot',
      })
      popup.select(conversation)
      popup.mount({ show: false })

      popup.onCustomMessageAction('buy', (event) => {
        console.log('Action has been triggered!', event.message.custom)
        onOpenModal()        
      })

      // chatbox.onSendMessage((message) => {
      //   const updateParticipants = async () => {
      //     // await to make sure the message is sent before updating the participants
      //     await sleep(200)
      //     conversation.setParticipant(me, { access: 'Read' })
      //     chatbox.select(conversation)
      //   }

      //   updateParticipants()
      // })

      session.onMessage(async (message) => {
        console.log('Message received', message)
        if (message.custom.unlock === 'true') {
          conversation.setParticipant(me, { access: 'ReadWrite' })
          popup.select(conversation)
        }
      })

      return () => session.destroy()
    })
  }, [])

  return <button type="button" id="btn-openPopup" ></button>
}

export default TalkJSButton
