import React, { useEffect, useState, useMemo } from 'react'
import usePackGroups from './hooks/usePackGroups'
import { Pack, PackGroup } from './types/packs'

import { Alert, AlertDescription, AlertIcon, Spinner, Stack, useToast } from '@chakra-ui/react'

import PackGroupTabs from './components/PackGroupTabs'
import PackCard from './components/PackCard'
import PartnerLogo from './components/PartnerLogo'
import PartnerFooter from './components/PartnerFooter'
import BuyingModal from './components/BuyingModal/BuyingModal'
import { FaDiscord, FaTwitter } from 'react-icons/fa'

import TalkJSChatbox from './components/TalkJSChatbox'

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'

// import required modules
import { Pagination } from 'swiper/modules'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'
import { userAnalyticsClient } from './clients/userAnalyticsClient'
import { PacksClient } from './clients/packsClient'

const CHATBOT_GROUP_ID = '_chatbot'
const CHATBOT_PACK_GROUP: PackGroup = {
  id: CHATBOT_GROUP_ID,
  name: 'THEO Chatbot',
  packs: [],
}

interface PacksWidgetProps {
  partnerToken: string,
  loadDinamicCSS?: boolean
}

const PacksWidget: React.FC<PacksWidgetProps> = ({
  partnerToken,
  loadDinamicCSS = true
}) => {
  const packsClient = useMemo(() => {
    return new PacksClient({ jwtToken: partnerToken })
  }, [partnerToken])
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isConfigLoaded, setIsConfigLoaded] = useState(false);
  const [logoImageAlt, setLogoImageAlt] = useState('');
  const [logoSubtitle, setLogoSubtitle] = useState('');
  const [footerText, setFooterText] = useState('');
  const [footerSubText, setFooterSubText] = useState('');
  const [footerSocialTwitter, setFooterSocialTwitter] = useState('');
  const [footerSocialDiscord, setFooterSocialDiscord] = useState('');
  const [twitterMessageUrl, setTwitterMessageUrl] = useState('');
  const [twitterMessageText, setTwitterMessageText] = useState('');
  const [partner, setPartner] = useState<string>('unknown');

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    packsClient.fetchPartnerConfiguration().then((config) => {
      if (config.cssUrl && loadDinamicCSS) {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = config.cssUrl;
        document.head.appendChild(link);
      }
      setLogoImageAlt(config.logoImageAlt || '');
      setLogoSubtitle(config.logoSubtitle || '');
      setFooterText(config.footerText || '');
      setFooterSubText(config.footerSubText || '');
      setFooterSocialTwitter(config.footerSocialTwitter || '');
      setFooterSocialDiscord(config.footerSocialDiscord || '');
      setTwitterMessageUrl(config.twitterMessageUrl || '');
      setTwitterMessageText(config.twitterMessageText || '');
      setPartner(config.partnerName);
      setIsConfigLoaded(true);

      userAnalyticsClient.packsLaunch(config.partnerName);
    });

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Threshold at 1024px for mobile or tablet vs. larger screens
  const isMobileOrTablet = useMemo(() => {
    return windowWidth <= 1024;
  }, [windowWidth]);

  const toast = useToast()

  const {
    packGroups: fetchedPackGroups,
    isLoadingPackGroups,
    errorPackGroups,
    fetchPackGroups,
  } = usePackGroups(partnerToken)

  const packGroups = useMemo(() => {
    return fetchedPackGroups.concat(CHATBOT_PACK_GROUP)
  }, [fetchedPackGroups])

  const [activePackGroupId, setActivePackGroupId] = useState<string>('')
  const [selectedPackId, setSelectedPackId] = useState<string | undefined>(undefined)
  const [selectedPack, setSelectedPack] = useState<Pack | undefined>(undefined)

  const handleOpenModal = ({ pack, packId }: { pack?: Pack; packId?: string }) => {
    if (pack) {
      setSelectedPackId(pack.id)
      setSelectedPack(pack)
    } else if (packId) {
      setSelectedPackId(packId)
      setSelectedPack(undefined)
    } else {
      console.error('No pack or packId provided')
    }
  }

  const handleCloseModal = () => {
    setSelectedPackId(undefined)
    setSelectedPack(undefined)
  }

  // Show toast on error
  useEffect(() => {
    if (errorPackGroups) {
      toast({
        title: 'Error fetching packs',
        description: errorPackGroups.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }, [errorPackGroups, toast])

  useEffect(() => {
    fetchPackGroups()
  }, [fetchPackGroups])

  useEffect(() => {
    if (packGroups.length > 0) {
      setActivePackGroupId(packGroups[0].id)
    }
  }, [packGroups])

  const activePackGroup = useMemo(() => {
    return packGroups.find((group) => group.id === activePackGroupId)
  }, [packGroups, activePackGroupId])

  return (
    <>
      <div className="packs-background"></div>
      <div className="packs-content">
        <PartnerLogo
          logoImageAlt={logoImageAlt}
          logoSubtitle={logoSubtitle}
        />
        <PackGroupTabs
          partner={partnerToken}
          packGroups={packGroups}
          onTabChange={setActivePackGroupId}
        />
        <div className="packs-container genopets-pack-container">
          {isLoadingPackGroups ? (
            <Stack spacing={4}>
              <Spinner size="xl" />
            </Stack>
          ) : errorPackGroups ? (
            <div className="packs-error">Error: {errorPackGroups?.message}</div>
          ) : activePackGroupId == CHATBOT_GROUP_ID ? (
            <>
              <TalkJSChatbox onOpenModal={handleOpenModal} partner={partner} />

              <Alert
                status="info"
                className="buying-modal-alert"
                borderRadius={'4px'}
                maxWidth="450px"
              >
                <AlertIcon className="buying-modal-alert-icon" />
                <AlertDescription className="buying-modal-alert-description">
                  THEO Chatbot is still in beta. If you encounter any problems, please use the
                  feedback form on the left.
                </AlertDescription>
              </Alert>
            </>
          ) : !isMobileOrTablet ? (
            activePackGroup &&
            activePackGroup.packs.map((pack) => (
              <PackCard key={pack.id} pack={pack} onOpenModal={handleOpenModal} />
            ))
          ) : (
            <Swiper pagination={true} modules={[Pagination]} className="mySwiper">
              {activePackGroup &&
                activePackGroup.packs.map((pack) => (
                  <SwiperSlide>
                    <PackCard key={pack.id} pack={pack} onOpenModal={handleOpenModal} />
                  </SwiperSlide>
                ))}
            </Swiper>
          )}
        </div>
        <PartnerFooter>
          <ul className="social-icons">
            <li>
              <a
                href={footerSocialTwitter}
                target="_blank"
                rel="noreferrer"
              >
                <FaTwitter fontSize={"20px"} />
              </a>

              <a
                href={footerSocialDiscord}
                target="_blank"
                rel="noreferrer"
              >
                <FaDiscord fontSize={"20px"} />
              </a>
            </li>
          </ul>

          <div className="footer-section-sub-text">
            <p>{footerText}</p>
            <p>{footerSubText}</p>
          </div>
          <div className="footer-tulle-logo">
            <a href="https://tulle.ai" target="_blank" rel="noreferrer">
              <img
                src={require('./assets/tulle-flat-white.png')}
                alt="Tulle AI Logo"
                style={{ display: 'block', margin: '0 auto' }}
              />
            </a>
          </div>
        </PartnerFooter>
      </div>
      {(selectedPackId || selectedPack) && isConfigLoaded && (
        <BuyingModal
          packId={selectedPackId}
          packDetails={selectedPack}
          isOpen={Boolean(selectedPackId) || Boolean(selectedPack)}
          onClose={handleCloseModal}
          partnerToken={partnerToken}
          twitterMessageUrl={twitterMessageUrl}
          twitterMessageText={twitterMessageText}
        />
      )}
    </>
  )
}

export default PacksWidget
