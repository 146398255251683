import { Outlet, useLocation } from "react-router-dom";
import { Box, Link, Text } from "@chakra-ui/react";
import Navbar from "../navbar";
import { ReactNode, useEffect } from "react";

import backgroundImage from '../../assets/bg-sa.png'; // Adjust the path as necessary

const version = process.env.REACT_APP_VERSION;

export default function Layout() {



  const location = useLocation();

  interface StyleMap {
    [key: string]: string;
  }

  const swooshStyles: StyleMap = {
    "/ai-trades/*": "opacity-5",
    default: "opacity-5",
  };

  const getSwooshStyle = (pathname: string): string => {
    // Check for an exact match first
    if (swooshStyles[pathname]) {
      return swooshStyles[pathname];
    }

    // Check for a wildcard match
    for (const key in swooshStyles) {
      if (key.endsWith("/*") && pathname.startsWith(key.slice(0, -2))) {
        return swooshStyles[key];
      }
    }

    // If none of the above, return default
    return swooshStyles.default;
  };



  return (
    <>
      <div id="top-bar"></div>

      {window.location.pathname == '/star-atlas' || window.location.pathname == '/genopets' ? (
        <div
          style={{
            backgroundImage: `url(${backgroundImage})`,
            zIndex: -1,
          }}
          className={` newbody fixed w-full h-full bg-no-repeat bg-cover bg-center ${getSwooshStyle(
            location.pathname
          )}`}
        />
      ) : (
        <div

          className={`fixed w-full h-full  pattern-bg`}
        />
      )}

      <Box id="container">
        <div id="page">





          <Navbar />
          <div className="page-content page-width">
            <Outlet />
          </div>
          <div id="footer">
            {/* <Link href="/updates">v.{version} beta</Link>{" "} */}
            {/* <span className="sep">|</span> <Link href="/faq">FAQ</Link> */}
          </div>
        </div>
        <div id="side-panel"></div>
      </Box>
    </>
  );
}

export function HomeLayout({ children }: { children: ReactNode }) {
  return (
    <Box _dark={{ backgroundColor: "#051a28" }} minH="100vh" overflowY="auto">
      <Navbar />
      {children}
      <div>
        {/* <Text color="#ed6cd8">v.{version} beta</Text>{" "}*/}
        {/* <span className="sep">|</span> <Link href="/faq">FAQ</Link> */}
      </div>
    </Box>
  );
}
