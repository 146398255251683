import React from 'react'
import { Flex, ModalBody, Spinner } from '@chakra-ui/react'
import { StateFrom } from 'xstate'
import LeftPanel from './LeftPanel'
import RightPanel from './RightPanel'

type BodyProps = {
  state: StateFrom<any>
  uiPrice?: string
  canBuy?: boolean
}

const Body: React.FC<BodyProps> = ({ state, uiPrice, canBuy }) => {
  let transactions = state.context.txs.concat(state.context.previousTxs)
  let previousTxs = state.context.previousTxs

  return (
    <ModalBody>
      {state.matches('init') ? (
        <Flex justifyContent="center" alignItems="center" height="200px">
          <Spinner size="xl" />
        </Flex>
      ) : (
        <Flex direction={{ base: 'column', md: 'row' }} justifyContent="space-between" className='buying-modal-flex'>
          <LeftPanel pack={state.context.packDetails} state={state} />
          <RightPanel
            pack={state.context.packDetails}
            state={state}
            uiPrice={uiPrice}
            transactions={transactions}
            previousTxs={previousTxs}
          />
        </Flex>
      )}
    </ModalBody>
  )
}

export default Body
