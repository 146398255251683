import React, { useEffect, useState, useRef } from 'react'
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  useDisclosure,
  Tooltip,
  Skeleton,
  useToast,
} from '@chakra-ui/react'
import StarAtlas from '../assets/staratlas.png'
import BuyingModal from '../components/Bundles/BuyingModal'
import useNeoChain from '../hooks/useNeoChain'
import { useUA } from '../contexts/userTracking'

import { BundleDetails, Payment } from '../components/Bundles/types'
import { tokens } from '../components/Bundles/consts'
import TalkJSButton from '../components/PacksWidget/components/TalkJSButton'

interface PackCardProps {
  pack: {
    bundle: BundleDetails
    paymentOptions: Payment[]
  }
  onOpenBuyingModal: (pack: { bundle: BundleDetails; paymentOptions: Payment[] }) => void
}

const PackCard: React.FC<PackCardProps> = ({ pack, onOpenBuyingModal }) => {
  const chainFns = useNeoChain() as any
  const { currencyToName } = chainFns['solana']

  const usdcPrice = pack?.paymentOptions?.find((option: any) => option.id === 'usdc')
  const listingPrice = pack?.paymentOptions?.find((option: any) => option.id === 'listingCurrency')

  let hoverLabel = ''
  listingPrice?.details.forEach((detail: any) => {
    const currentCurrencyKey = currencyToName(detail.mint) as keyof typeof tokens
    const decimals = tokens[currentCurrencyKey].decimals
    const price = (detail.price / 10 ** decimals).toFixed(2)
    hoverLabel += `${price} ${currentCurrencyKey.toUpperCase()} + `
  })
  // remove the last " + "
  hoverLabel = hoverLabel.slice(0, -3)

  const usdcDecimals = 10 ** tokens.usdc.decimals

  return (
    <div className="pack-card-atlas bg-[#202024] flex flex-col w-full">
      <div className="pt-[26px] flex flex-col flex-1">
        <div className="pack-name text-center text-starAtlas-brightYellow text-xl font-bold uppercase tracking-wide pb-5 break-words px-2">
          {pack?.bundle?.description}
        </div>
        <div className="w-full flex justify-center px-4">
          <img src={pack?.bundle?.image} alt={pack?.bundle?.name} width={157} />
        </div>
        <div className="pack-price flex items-center justify-center gap-2 pt-3 pb-3 mt-auto">
          <span>PRICE</span>
          <Tooltip label={hoverLabel} fontSize="md" placement="top" hasArrow>
            <span className="text-starAtlas-brightYellow flex items-center gap-1">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 17.6238C13.7797 17.6238 17.625 13.7785 17.625 8.99878C17.625 4.21906 13.7797 0.373778 9 0.373779C4.22028 0.373779 0.375 4.21906 0.375001 8.99878C0.375001 13.7785 4.22028 17.6238 9 17.6238Z"
                  fill="#2775CA"
                />
                <path
                  d="M11.1562 10.2692C11.1562 9.01669 10.4804 8.58723 9.12872 8.40832C8.16323 8.26513 7.97013 7.97886 7.97013 7.47776C7.97013 6.97667 8.29199 6.65466 8.93562 6.65466C9.51492 6.65466 9.83677 6.86939 9.99766 7.40622C10.0299 7.51358 10.1264 7.58513 10.223 7.58513L10.7378 7.58513C10.8666 7.58513 10.9632 7.47776 10.9632 7.33467L10.9632 7.29885C10.8344 6.51148 10.2551 5.90311 9.51492 5.83156L9.51492 4.97264C9.51492 4.82946 9.41837 4.7221 9.25748 4.68628L8.77473 4.68628C8.64597 4.68628 8.54942 4.79364 8.51722 4.97264L8.51722 5.79574C7.55173 5.93893 6.9403 6.65466 6.9403 7.5494C6.9403 8.73041 7.58393 9.1956 8.93562 9.3746C9.83677 9.55351 10.1264 9.76824 10.1264 10.3409C10.1264 10.9135 9.6758 11.3072 9.06438 11.3072C8.22757 11.3072 7.93792 10.9134 7.84137 10.3766C7.80924 10.2335 7.71269 10.1619 7.61614 10.1619L7.06898 10.1619C6.9403 10.1619 6.84375 10.2692 6.84375 10.4124L6.84375 10.4482C6.97243 11.3429 7.48739 11.9871 8.54943 12.1661L8.54943 13.025C8.54943 13.1681 8.64597 13.2755 8.80686 13.3113L9.28961 13.3113C9.41837 13.3113 9.51492 13.2039 9.54712 13.025L9.54712 12.1661C10.5126 11.9871 11.1562 11.2355 11.1562 10.2692V10.2692Z"
                  fill="white"
                />
                <path
                  d="M7.17215 14.3022C4.3766 13.3134 2.94296 10.241 3.98239 7.52177C4.51999 6.03857 5.70272 4.90853 7.17215 4.37881C7.31554 4.30821 7.38719 4.20227 7.38719 4.02564L7.38719 3.53127C7.38719 3.38998 7.31553 3.28404 7.17215 3.24878C7.13628 3.24878 7.06462 3.24878 7.02876 3.28404C3.62396 4.34347 1.76023 7.91029 2.83544 11.2651C3.48057 13.2427 5.02173 14.7613 7.02876 15.3969C7.17215 15.4675 7.31554 15.3969 7.35132 15.2556C7.38719 15.2204 7.38719 15.185 7.38719 15.1144L7.38719 14.62C7.38719 14.514 7.27967 14.3728 7.17215 14.3022ZM10.9712 3.28404C10.8279 3.21344 10.6845 3.28404 10.6487 3.42532C10.6128 3.46067 10.6128 3.49592 10.6128 3.56661L10.6128 4.06098C10.6128 4.20227 10.7203 4.34347 10.8279 4.41416C13.6234 5.4029 15.057 8.47526 14.0176 11.1945C13.48 12.6777 12.2973 13.8078 10.8279 14.3375C10.6845 14.4081 10.6128 14.514 10.6128 14.6907L10.6128 15.185C10.6128 15.3263 10.6845 15.4323 10.8279 15.4675C10.8637 15.4675 10.9354 15.4675 10.9712 15.4323C14.376 14.3728 16.2398 10.806 15.1646 7.45117C14.5194 5.43825 12.9424 3.9197 10.9712 3.28404V3.28404Z"
                  fill="white"
                />
              </svg>
              <span className="pt-[2px]">
                {parseFloat((usdcPrice?.details[0].price! / usdcDecimals).toFixed(2))}
              </span>
            </span>
          </Tooltip>
        </div>

        <div className="w-full px-10">
          <div className="w-full h-0.5 pt-0.5 bg-zinc-900 bg-opacity-95 mx-auto" />
        </div>

        <div className="px-4 pb-6 pt-3">
          {pack?.bundle?.items?.map((item: any) => (
            <div className="pack-item w-full flex justify-between" key={item.name}>
              <span>{item.name}</span>
              <span className="text-starAtlas-brightYellow">x{item.quantity}</span>
            </div>
          ))}
        </div>
      </div>
      <button
        className="buy-pack-button w-full bg-slate-800 py-4"
        onClick={() => onOpenBuyingModal(pack)}
      >
        BUY PACK
      </button>
    </div>
  )
}

const StarAtlasSkins: React.FC = () => {
  const { gaPackSelectPack } = useUA()

  const [isLoading, setIsLoading] = useState(false)
  const [selectedPack, setSelectedPack] = useState<{
    bundle: BundleDetails
    paymentOptions: Payment[]
  }>()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [packType, setPackType] = useState<string>('STARTER')
  const [offers, setOffers] = useState<any[]>([])
  const chainFns = useNeoChain() as any
  const { getOffers } = chainFns['solana']

  const toast = useToast()

  useEffect(() => {
    const fetchOffers = async () => {
      setIsLoading(true)
      const offersData = await getOffers()
      setOffers(offersData)
      setIsLoading(false)
    }

    fetchOffers()
  }, [])

  const handleShare = () => {
    var shareURL = 'https://twitter.com/intent/tweet?' //url base

    // gaSharedStarAtlasSkin(skinType);

    var params: { [key: string]: string } = {
      url: 'https://neoswap.cc/star-atlas',
      text: `I got my @StarAtlas pack on @neoswap_ai! \n\nGet yours at: `,
    }

    for (var prop in params) shareURL += '&' + prop + '=' + encodeURIComponent(params[prop])
    window.open(
      shareURL,
      '',
      'left=0,top=50,width=550,height=450,personalbar=0,toolbar=0,scrollbars=0,resizable=0'
    )
  }

  const openBuyingModal = (pack: { bundle: BundleDetails; paymentOptions: Payment[] }) => {
    gaPackSelectPack(pack.bundle.uniqueId, pack.bundle.id)
    setSelectedPack(pack)
    onOpen()
  }

  const handleBuyButton = () => {
    console.log('handleBuyButton')
    toast({
      title: 'Coming Soon',
      description: 'This feature is not available yet',
      status: 'error',
      duration: 9000,
      isClosable: true,
    })
  }

  return (
    <div>
      <div className="flex flex-col items-center justify-start w-full">
        <img src={StarAtlas} alt="Star Atlas" style={{ maxWidth: '200px' }} />
        <h2 className="text-center text-amber-300 text-xl rogan-regular leading-10 tracking-widest uppercase mb-4">
          SPECIAL PACKS
        </h2>
      </div>
      <div className="w-fit mx-auto pb-4">
        <h3 className="text-white text-md rogan-regular pt-4 pb-2 uppercase">
          Select a pack to get started.
        </h3>
        <div className="bg-[#19191D]">
          <div className="mx-auto mb-4 max-w-full">
            <div className="bg-starAtlasBg shadow">
              <div className="flex rogan-regular gap-1">
                <div
                  className="bg-goldenGrey w-1/2"
                  onClick={() => {
                    setPackType('STARTER')
                  }}
                >
                  <div className="bg-yellow-radiant border-b-2 border-amber-300 w-full h-full text-center text-white text-lg leading-loose flex items-center justify-center gap-2 p-2 cursor-pointer">
                    STARTER PACKS
                  </div>
                </div>
                <div
                  className="bg-goldenGrey w-1/2"
                  onClick={() => {
                    // setPackType('QUEST');
                  }}
                >
                  <div className="bg-[#252528] border-b-2 border-[#252528]  w-full h-full text-center text-white text-lg leading-loose flex items-center justify-center gap-2 p-2 cursor-pointer">
                    QUEST PACKS (COMING SOON)
                  </div>
                </div>
                <div
                  className="bg-goldenGrey w-1/2"
                  onClick={() => {
                    // setPackType('REFIL');
                  }}
                >
                  <div className="bg-[#252528] border-b-2 border-[#252528]  w-full h-full text-center text-white text-lg leading-loose flex items-center justify-center gap-2 p-2 cursor-pointer">
                    REFILL PACKS (COMING SOON)
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 overflow-x-auto gap-4 py-4 px-20">
            {isLoading
              ? Array.from({ length: 4 }).map((_, index) => (
                  <Skeleton key={index} height="350px" borderRadius="md" />
                ))
              : offers.map((pack) => (
                  <PackCard
                    key={pack.bundle.name}
                    pack={pack}
                    onOpenBuyingModal={openBuyingModal}
                  />
                ))}
          </div>

          <div className="px-4">
            <div className="w-full h-0.5 bg-white bg-opacity-5" />
          </div>

          <div className="w-full flex items-center justify-center py-8">
            <Button
              className="gap-2 !min-w-[314px] w-auto"
              onClick={() => {
                handleShare()
              }}
              rounded={0}
            >
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.8319 6.42682L14.0437 0.498779H12.8087L8.28327 5.64602L4.66883 0.498779H0.5L5.96574 8.28231L0.5 14.4988H1.7351L6.51406 9.06312L10.3312 14.4988H14.5L8.8316 6.42682H8.8319ZM7.14026 8.35089L6.58647 7.57583L2.18013 1.40855H4.07717L7.63314 6.38574L8.18693 7.1608L12.8093 13.6304H10.9122L7.14026 8.35119V8.35089Z"
                  fill="white"
                />
              </svg>

              <span>SHARE</span>
            </Button>
          </div>
        </div>
        <div className="px-10 mt-6">
          <Alert status="info" background={'#121117'} borderRadius={'4px'} width="full">
            <AlertIcon color={'#FFBE4D'} />
            <AlertDescription className="text-gray-100 text-xs tracking-tight">
              For accurate pack pricing, please select and confirm quickly as prices and quantities
              may change. If signing fails, try generating a new pack.
              <br />
              Swapping to ATLAS and/or USDC is executed with jup.ag and and is limitted to 5%
              slippage tolerance.
              <br />
              Purchase price includes 5% NeoSwap convenience fee.
            </AlertDescription>
          </Alert>
        </div>
      </div>
      {selectedPack && (
        <BuyingModal
          isOpen={isOpen}
          onClose={onClose}
          offer={selectedPack.bundle}
          paymentOptions={selectedPack.paymentOptions}
        />
      )}
      <TalkJSButton onOpenModal={handleBuyButton} partner='staratlas'/>
    </div>
  )
}
export default StarAtlasSkins
