import React, { useEffect, useState } from 'react';
import { PackGroup } from '../types/packs';
import { userAnalyticsClient } from '../clients/userAnalyticsClient'

const PackGroupTabs: React.FC<{ partner: string, packGroups: PackGroup[]; onTabChange: (packGroupId: string) => void }> = ({ partner, packGroups, onTabChange }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Threshold at 1024px for mobile or tablet vs. larger screens
  const isMobileOrTablet = windowWidth <= 1024;

  const [activeTab, setActiveTab] = useState<string>(packGroups[0]?.id || '');

  const handleTabClick = (packGroupId: string) => {
    userAnalyticsClient.packsSelectPackGroup(partner, packGroupId);
    setActiveTab(packGroupId);
    onTabChange(packGroupId);
  };

  useEffect(() => {
    setActiveTab(packGroups[0]?.id || '');

  }, [packGroups[0]?.id]);

  return (
    isMobileOrTablet ? (
      <div className="packs-pack-group-tabs">
        <select
          value={activeTab}
          onChange={(e) => handleTabClick(e.target.value)}
          className="packs-tab-dropdown"
        >
          {packGroups.map((group) => (
            <option
              key={group.id}
              value={group.id}
            >
              {group.name}
            </option>
          ))}
        </select>
      </div>) :
      (
        <div className="packs-pack-group-tabs">
          {packGroups.map((group) => (
            <button
              key={group.id}
              className={`packs-tab-button ${activeTab === group.id ? 'active' : ''}`}
              onClick={() => handleTabClick(group.id)}
            >
              {group.name}
            </button>
          ))}
        </div>
      )

  );
};

export default PackGroupTabs;
