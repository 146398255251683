import { useState, useEffect } from "react";
import {

  Center,
  Container,
  Flex,

  Spinner,
  useToast,

  Alert,
  AlertDescription,
  AlertIcon
} from "@chakra-ui/react";

import { Helmet } from "react-helmet-async";

import { useAppContext } from "../contexts/appContext";
import Firebase from "../services/firebase";

import { httpsCallable } from "firebase/functions";
import logo from "../assets/logo-icon.png";
import { confetti } from "../services/confetti";


const auctions = [
  {
    id: "test",
    name: "Tensorian x Baxus x Tulle Smart Auction",
    startAt: 1702486800,
    chain: "solana",
    partyDuration: 86400
  }
];

export default function Points() {

  const [points, setPoints] = useState<any>({});
  const [claiming, setClaim] = useState<boolean>(false);
  const { uid, token } = useAppContext();
  const [loading, setLoading] = useState<boolean>(false);
  const toast = useToast();




  async function pointsCall() {
    const points = httpsCallable(Firebase.getFnsApp(), "points-getUser", { timeout: 540000 });
    return await points();

  }

  const getPoints = async () => {
    setLoading(true)
    try {
      toast({
        title: "Please wait...",
        description: `We are scanning your wallet for Tulle Points`,
        status: "warning",
        duration: 3000,
        isClosable: true,
        styleConfig: {
          zIndex: 999999,
        },
      });

      let { data } = (await pointsCall()) as { data: any };
      console.log(data)
      setPoints(data)
      setLoading(false);
      confetti.fire();
    } catch (e: any) {
      console.error("error fetching", e);
      setLoading(false);

    }

  };
  useEffect(() => {
    document.body.classList.add("newbody");
    console.log(token)
    if (uid) {
      getPoints();
    }
  }, [uid]);





  const PointsUI = () => (
    <div className="flex-col  w-full justify-center items-center gap-16 inline-flex">
      <div className="self-stretch flex-col justify-center items-center gap-12 flex">

        <div className="text-center">
          <span className="text-earth text-xl font-medium leading-snug">
            Congratulations, your total points are:
          </span>
        </div>

        {loading ? (
          <Flex
            p={10}
            alignItems={"center"}
            justifyContent={"center"}
            gap={4}
            flexDir="column"
          >
            <Spinner size={"xl"} className="text-earth" emptyColor="#666666" />{" "}
            Loading points...
          </Flex>
        ) : (

          <div className="flex items-center gap-2">
            <div className="flex flex-col justify-end">
              <div className="flex mt-2">

                <span className="text-earth text-5xl md:text-6xl font-extrabold !leading-[50px]">{points?.points ? points?.points : 0}<img src={logo} style={{ height: '1em', float: 'right', marginTop: '-0.2rem' }} />
                </span>
              </div>
            </div>
          </div>


        )}

        <Flex
          maxWidth={"600px"}>
          <Alert
            status="info"
            className="info-alert"
            background={
              "linear-gradient(90deg, rgb(102 100 100 / 13%) -1.52%, rgba(255, 255, 255, 0.024) 104.35%)"

            }
            borderRadius={"4px"}
          >
            <AlertIcon color={"#6C60FF"} />
            <AlertDescription className="text-earth">
              {"Points are our way of recognizing your engagement on Tulle. Complete swaps, refer friends, and more to earn points. Each action adds to your balance, opening doors to exclusive benefits. Keep active, collect points, and enjoy being a Tulle user!"}
            </AlertDescription>
          </Alert>
        </Flex>
      </div>

    </div>
  );
  const isObjectEmpty = (obj: any) => {
    return Object.keys(obj).length === 0;
  };

  return (
    <>
      <Helmet>
        <title>Tulle - Points</title>
      </Helmet>
      <Center>
        <Container maxW="5xl">
          <div className="flex flex-col-reverse lg:flex-row justify-center items-center gap-6   lg:justify-between pt-10">
            <PointsUI />
          </div>
        </Container>
      </Center>
    </>
  );
}
