import React from 'react';
import { Box, SimpleGrid, Heading, Text, Image, Button, VStack, Flex, Link } from "@chakra-ui/react";
import potatocat from "../assets/dapps/potatocat.jpg";
import staratlas from "../assets/dapps/staratlas.jpg";
import genopets from "../assets/dapps/genopets.png";

const DAppsCards = () => {
    const cardsData = [
        {
            title: "Potato Cat",
            description: "Get NFTs you want using NFTs you own",
            image: potatocat,
            link: "https://app.potatocat.ai"
        },
        {
            title: "Star Atlas Starter Packs",
            description: "Get started on Star Atlas game with your favorite pack",
            image: staratlas,
            link: "/star-atlas"
        },
        {
            title: "Genopets Starter Packs",
            description: "Get started on Genopets game with your favorite pack",
            image: genopets,
            link: "/genopets"
        }
    ];

    return (
        <SimpleGrid columns={[1, null, 3]} spacing={4} mt={6} w="full">
            {cardsData.map((card, index) => (
                <Flex key={index} direction="column" borderWidth="1px" borderRadius="lg" overflow="hidden" h="full" className='packs-widget'>
                    <Image src={card.image} alt={card.title} objectFit="cover" h="200px" />
                    <VStack flex={1} p={4} spacing={4} align="stretch">
                        <Box>
                            <Heading as="h3" size="md" mb={2}>
                                {card.title}
                            </Heading>
                            <Text>{card.description}</Text>
                        </Box>
                        <Link href={card.link} isExternal mt="auto">
                            <Button className="buy-pack-btn" w="full">
                                Launch App
                            </Button>
                        </Link>
                    </VStack>
                </Flex>
            ))}
        </SimpleGrid>
    );
};

export default DAppsCards;